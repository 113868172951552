import React from 'react';
import { Award, Users, Target, Smile } from 'lucide-react';

const About = () => {
  return (
    <div className="py-16">
      <div className="container mx-auto px-4">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-secondary mb-4">About Us</h1>
          <p className="text-xl text-gray-600 max-w-5xl mx-auto mb-4">
            At Map Your Career, our story begins with our own struggles—struggles that many students and their families face when it comes to navigating the complex world of education and career choices. As young professionals, we’ve been there too. We’ve experienced the confusion, the overwhelming documentation, and the lack of clear guidance in choosing the right career path. The journey to finding the right college, whether in India or abroad, felt like an endless maze.
          </p>
          
          <p className="text-xl text-gray-600 max-w-5xl mx-auto mb-4">
            Like many aspiring students, we were caught in a web of complex procedures, unclear options, and the constant pressure to make the "right" decision. With no one to guide us, we often felt lost, unsure, and overwhelmed. What made it even harder was watching our peers, bright and talented students, compromise on their dreams simply because they didn’t have the right information or support to make informed choices.
          </p>
          
          <p className="text-xl text-gray-600 max-w-5xl mx-auto mb-4">
            We knew we weren’t alone. We saw the same struggles in others, and it was clear—something needed to change. That’s when we decided to take matters into our own hands.
          </p>
          
          <p className="text-xl text-gray-600 max-w-5xl mx-auto mb-4">
            Driven by our personal experiences, we founded Map Your Career. Our mission is simple: to make the counselling process for aspirants—clear, straightforward, and supportive. We want to be the guide that we never had, helping students from all streams discover the best opportunities and reach their dreams without the confusion and frustration we once faced.
          </p>
          
          <p className="text-xl text-gray-600 max-w-5xl mx-auto mb-4">
            Through Map Your Career, we’re committed to breaking down barriers, simplifying the process, and empowering students to make the right decisions for their future. Whether it's choosing the right medical college, understanding the admission process, or exploring global options, we’re here to help every step of the way.
          </p>
          
          <p className="text-xl text-gray-600 max-w-5xl mx-auto mb-4">
            We believe that every student deserves a chance to follow their dreams without compromise. And with Map Your Career, we’re proud to be part of that journey.
          </p>
        </div>

        {/* Mission & Vision */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-secondary mb-4">Our Mission</h2>
            <p className="text-gray-600">
              At Map Your Career, our mission is to guide students through the challenging and often overwhelming process of choosing the right career and educational opportunities.
            </p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-secondary mb-4">We aim to:</h2>
            <p className="text-gray-600">
            <div>1.	<b>Simplify the Counselling Process:</b> We break down the complex admission procedures, making them easy to understand and navigate.</div>
            <div>2.	<b>Provide Personalized Guidance:</b> Offering tailored advice based on each student’s goals, needs, and preferences to help them make the best possible decisions.</div>
            <div>3.	<b>Raise Awareness:</b> We provide accurate and up-to-date information about medical colleges in India and abroad, ensuring students have access to the knowledge they need to make informed choices.</div>
            <div>4.	<b>Support Students at Every Step:</b> From application to admission, we are committed to being there for students, guiding them through each stage of the journey.</div>
            <div>5.	<b>Inspire Confidence:</b> We believe every student has the potential to achieve their dreams. Our mission is to empower students to pursue their goals without hesitation or fear.</div>

            </p>
          </div>
        </div>

        {/* Stats */}
        <h2 className="text-2xl font-bold text-secondary mb-8">Core Ideology:</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-16">
        
          {[
            { icon: <Users className="w-8 h-8" />, number: "Transparency", label: "" },
            { icon: <Award className="w-8 h-8" />, number: "Quality", label: "" },
            { icon: <Target className="w-8 h-8" />, number: "Commitment", label: "" },
            { icon: <Smile className="w-8 h-8" />, number: "Excellence", label: "" }
          ].map((stat, index) => (
            <div key={index} className="text-center">
              <div className="text-secondary mb-2">{stat.icon}</div>
              <div className="text-3xl font-bold text-secondary mb-1">{stat.number}</div>
              <div className="text-gray-600">{stat.label}</div>
            </div>
          ))}
        </div>

        {/* Team Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-center text-secondary mb-8">Our Expert Team</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                image: "./images/Chetan.jpg",
                name: "CHETAN BHANDARI",
                role: "Director – Counsellor"
              },
              {
                image: "",
                name: "MAHEK GADA",
                role: "Co-Founder"
              },
              {
                image: "",
                name: "AAMIR",
                role: "Co-Founder"
              }
            ].map((member, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <img src={member.image} alt={member.name} className="w-full h-48 object-cover" />
                <div className="p-4">
                  <h3 className="text-xl font-bold text-secondary">{member.name}</h3>
                  <p className="text-gray-600">{member.role}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;