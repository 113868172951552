import React from 'react';
import { Phone, Mail, MapPin } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-secondary text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">Contact Info</h3>
            <div className="space-y-2">
              <p className="flex items-center"><Phone className="h-4 w-4 mr-2" /> +91 8369698025</p>
              <p className="flex items-center"><Mail className="h-4 w-4 mr-2" /> info@mapyourcareer.net</p>
              <p className="flex items-center"><MapPin className="h-4 w-4 mr-2" /> Mumbai, India</p>
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="/services" className="hover:text-accent">Our Services</a></li>
              <li><a href="/about" className="hover:text-accent">About Us</a></li>
              <li><a href="/contact" className="hover:text-accent">Contact Us</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Follow Us</h3>
            <p>Stay connected with us on social media for latest updates.</p>
          </div>
        </div>
        <div className="mt-8 pt-4 border-t border-gray-600 text-center">
          <p>&copy; {new Date().getFullYear()} Map Your Career. All rights reserved. Designed and Developed by Galaxy Of Code.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;