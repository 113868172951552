import React from 'react';
import { CheckCircle, BookOpen, FileText, Building2, Users, Presentation, Phone } from 'lucide-react';

const Services = () => {
  return (
    <div className="py-16">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold text-center text-secondary mb-12">Our Services</h1>
        
        {/* Main Services */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {[
            {
              icon: <BookOpen className="w-12 h-12 text-secondary" />,
              title: "College Selection Guidance",
              description: "Expert advice on choosing the right medical college based on your NEET score and preferences."
            },
            {
              icon: <FileText className="w-12 h-12 text-secondary" />,
              title: "Documentation Support",
              description: "Complete assistance with application forms and required documentation."
            },
            {
              icon: <Building2 className="w-12 h-12 text-secondary" />,
              title: "College Profile Analysis",
              description: "Detailed analysis of medical colleges, their infrastructure, and placement records."
            },
            {
              icon: <Users className="w-12 h-12 text-secondary" />,
              title: "Personal Counselling",
              description: "One-on-one sessions with experienced counsellors for personalized guidance."
            },
            {
              icon: <Presentation className="w-12 h-12 text-secondary" />,
              title: "Admission Process Support",
              description: "Step-by-step guidance through the entire admission process."
            },
            {
              icon: <Phone className="w-12 h-12 text-secondary" />,
              title: "24/7 Support",
              description: "Round-the-clock assistance for all your queries and concerns."
            }
          ].map((service, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
              <div className="mb-4">{service.icon}</div>
              <h3 className="text-xl font-bold mb-2">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>

        {/* Process Section */}
        <div className="bg-gray-50 p-8 rounded-lg">
          <h2 className="text-3xl font-bold text-center text-secondary mb-8">Our Process</h2>
          <div className="space-y-4">
            {[
              "Initial consultation and profile evaluation",
              "Analysis of NEET score and eligible colleges",
              "Personalized college recommendations",
              "Documentation preparation and verification",
              "Application submission and tracking",
              "Admission confirmation support"
            ].map((step, index) => (
              <div key={index} className="flex items-center space-x-3 bg-white p-4 rounded-lg">
                <CheckCircle className="text-accent" />
                <span>{step}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;