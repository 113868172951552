import React from 'react';
import { ArrowRight, Award, Users, BookOpen } from 'lucide-react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[600px] flex items-center">
        <div className="absolute inset-0 z-0">
          <img
            src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80"
            alt="Medical students"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-secondary bg-opacity-70"></div>
        </div>
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-2xl text-white">
            <h1 className="text-5xl font-bold mb-6">Your Journey to Medical Excellence Starts Here</h1>
            <p className="text-xl mb-8">Expert guidance for NEET counselling to help you secure admission in top medical colleges.</p>
            <Link to="/contact" className="bg-accent text-secondary px-8 py-3 rounded-full font-bold inline-flex items-center hover:bg-opacity-90 transition-colors">
              Get Started <ArrowRight className="ml-2" />
            </Link>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-secondary">Why Choose Us</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center p-6 bg-white rounded-lg shadow-lg">
              <Award className="w-12 h-12 mx-auto mb-4 text-secondary" />
              <h3 className="text-xl font-bold mb-2">Expert Counsellors</h3>
              <p className="text-gray-600">Experienced professionals to guide you through the admission process</p>
            </div>
            <div className="text-center p-6 bg-white rounded-lg shadow-lg">
              <Users className="w-12 h-12 mx-auto mb-4 text-secondary" />
              <h3 className="text-xl font-bold mb-2">Personalized Guidance</h3>
              <p className="text-gray-600">Tailored counselling sessions based on your preferences and scores</p>
            </div>
            <div className="text-center p-6 bg-white rounded-lg shadow-lg">
              <BookOpen className="w-12 h-12 mx-auto mb-4 text-secondary" />
              <h3 className="text-xl font-bold mb-2">Complete Support</h3>
              <p className="text-gray-600">End-to-end assistance from college selection to admission</p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-secondary text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Start Your Medical Journey?</h2>
          <p className="text-xl mb-8">Book a free counselling session with our experts today</p>
          <Link to="/contact" className="bg-accent text-secondary px-8 py-3 rounded-full font-bold hover:bg-opacity-90 transition-colors">
            Schedule Consultation
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home;